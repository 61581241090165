<template>
    <footer v-if="loggedIn" class="px-8 2xl:px-0 pt-12 pb-16 bg-eco-blue-primary text-sm">
        <div class="footer-content">
            <div class="md:flex justify-between items-center xl:items-start">
                <div class="flex flex-wrap items-center md:w-1/2 mb-10 md:mt-0">
                    <img src="../../../images/logo-white.svg"
                         width="260"
                         height="56"
                         alt="EcoClaim"
                         loading="lazy"
                         decoding="async"
                         class="mr-8">
                </div>
                <ul v-if="rootStore.currentCompany" class="footer-nav mt-2">
                    <li class="footer-nav_item">
                        <RouterLink :to="{ name: 'dashboard' }">Dashboard</RouterLink>
                    </li>
                    <!--<li class="footer-nav_item">
                        <a href="" @click.prevent="todo">Transfer Stations</a>
                    </li>-->
                    <li class="footer-nav_item">
                        <RouterLink :to="{ name: 'reports' }">Reports</RouterLink>
                    </li>
                    <!--<li class="footer-nav_item">
                        <a href="" @click.prevent="todo">Admin</a>
                    </li>-->
                    <li class="footer-nav_item">
                        <a href="/logout">Logout</a>
                    </li>
                </ul>
            </div>
            <div class="pt-48 pb-0 mb-0 flex justify-center">
                <div class="pt-6 text-eco-gray-400">
                    © {{ new Date().getFullYear() }} EcoClaim Solutions Inc. All Rights Reserved
                    <span v-if="rootStore.hasRole('ROLE_ADMIN')">
                        |
                        <a href="/admin" class="footer-admin_link">
                            Admin
                        </a>
                    </span>
                </div>
            </div>
        </div>
    </footer>
    <footer v-else>
        <div class="mb-8 text-gray-700 text-center text-sm -mt-24 sm:mt-8">
            © {{ new Date().getFullYear() }} EcoClaim Solutions Inc. All Rights Reserved
        </div>
    </footer>
</template>

<script setup>
import { computed } from 'vue';
import { useRootStore } from '@/user/stores/root';

const rootStore = useRootStore();

const loggedIn = computed(() => rootStore.loggedIn);
</script>
